<template>
   <v-responsive class="tutorials page mb-5">
       <v-layout :class="{'mx-0 my-2 pa-0': $vuetify.breakpoint.smAndDown, 'my-5 py-5': $vuetify.breakpoint.mdAndUp}" style="opacity:.25;">
           <h1 :class="{'display-2 secondary--text font-weight-light': $vuetify.breakpoint.smAndDown, 'display-4 secondary--text font-weight-light': $vuetify.breakpoint.mdAndUp}">Motion Tracking</h1>

          <!-- spacer to replace sort button row -->
      <div :class="{'py-1 my-1': $vuetify.breakpoint.smAndDown, 'py-5 my-5': $vuetify.breakpoint.mdAndUp}"></div>
      <!-- end spacer --> 


    </v-layout>
    <TutorialList :category="category" />
   </v-responsive>
</template>

<script>
//import template for list of tutorials
import TutorialList from '@/components/TutorialList'
export default {
  components: { TutorialList },
   data() {
    return {
    //pass topic variable to list component
    category: 'MotionTracking', 
    //initialize variable to show/hide progress bar
    loading: ''
  }  
},    } 
</script>
<style>
  .tutorials {
    background-image: url('../assets/bella_awake.png');
    left: 0;
    top: 75px;
    background-repeat: no-repeat;
    min-height: 100vh;
    min-width: 10vw;
    max-width: 100vw;
    padding: 0;
  }

.tutorial.Animation {
  border-left: 15px solid grey;
  border-bottom: 1px solid grey;
}
.tutorial.Compositing {
  border-left: 15px solid #734c7c;
  border-bottom: 1px solid #734c7c;
}
.tutorial.GameCreation {
  border-left: 15px solid #AEEA00;
  border-bottom: 1px solid #AEEA00;
}
.tutorial.Modeling {
  border-left: 15px solid orange;
  border-bottom: 1px solid orange;
}
.tutorial.MotionTracking {
  border-left: 15px solid indigo;
  border-bottom: 1px solid indigo;
}
.tutorial.Rendering {
  border-left: 15px solid red;
  border-bottom: 1px solid red;
}
.tutorial.Rigging {
  border-left: 15px solid olive;
  border-bottom: 1px solid olive;
}
.tutorial.Simulation {
  border-left: 15px solid maroon;
  border-bottom: 1px solid maroon;
}
.tutorial.VideoEditing {
  border-left: 15px solid blue;
  border-bottom: 1px solid blue;
}

.topic.Animation {
  color: grey;
}
.topic.Compositing {
  color: #734c7c;
}
.topic.GameCreation {
  color: #AEEA00;
}
.topic.Modeling {
  color: orange;
}
.topic.MotionTracking {
  color: indigo;
}
.topic.Rendering {
  color: red;
}
.topic.Rigging {
  color: olive;
  font-weight: 300;
}
.topic.Simulation {
  color: maroon;
  font-weight: 300;
}
.topic.VideoEditing {
  color: blue;
  font-weight: 300;
}

.headline {
  font-size: 2em;
}
.router-link.h2 {
  font-weight: light;
}
.fade {
  opacity: .7;
}
</style>
